import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

const BottomStrip = props => {
  // Detect when user scrolls to bottom of page so we can hide the woods strip
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (
        window.scrollY > 200 && window.scrollY + window.innerHeight < document.body.offsetHeight - 200
      ) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    })
  })

  return (
    <div>
      {/* Desktop */}
      <div
        className={`sticky-nav bg-black text-white fixed w-full z-30 transition-all duration-500 ease-in-out ${
          scrolled ? "bottom-0" : "-bottom-16"
        }`}
      >
        <div className="text-right flex justify-center items-center space-x-4 p-3 lg:pb-3 pb-6">
            <Link to="/brochure-downloads/" className="btn white small font-bold text-xxs whitespace-nowrap tracking-wide md:tracking-widest">
              Download a brochure
            </Link>
            <Link to="/visit-the-factory/" className="btn white small font-bold text-xxs whitespace-nowrap tracking-wide md:tracking-widest">
              Visit the factory
            </Link>
        </div>
      </div>
    </div>
  )
}

export default BottomStrip
