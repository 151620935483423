import React from "react"
import { Link } from "gatsby"
import Fade from "react-reveal/Fade"

const BlockCta = props => {
  if (!props.disabled) {
    return (
      <div className="bg-verylightgrey text-black">
        <div className="container">
          <div className="flex flex-col lg:flex-row gap-12">
            <Fade distance="20px" bottom>
              <div className="flex-1 flex flex-col text-center justify-start items-center group relative lg:pb-0 pb-10 w-full lg:w-1/3 mb-5">
                <Link className="no-underline" to="/visit-the-factory/">
                  <h3 className="font-display text-gold rfs:text-4xl max-w-xl">
                    Visit the Factory
                  </h3>
                  <div className="w-10 separator mt-4"></div>
                  <p className="max-w-sm my-5">
                    It’s true when they say seeing is believing. <br />
                    Get exclusive access to our premises and see our expert
                    engineers in action.
                  </p>
                  <div className="bg-darkgrey rounded-full flex m-auto h-9 w-9 justify-center items-center transition duration-500 ease-in-out group-hover:bg-gold">
                    <i
                      className="far fa-chevron-right text-white text-sm h-3"
                      aria-hidden
                    ></i>
                  </div>
                </Link>
              </div>
              <div className="flex-1 flex flex-col text-center justify-center items-center group relative lg:pb-0 pb-10 w-full lg:w-1/3 mb-5">
                <Link className="no-underline" to="/request-a-quote/">
                  <h3 className="font-display text-gold rfs:text-4xl max-w-xl">
                    Request a quote
                  </h3>
                  <div className="w-10 separator mt-4"></div>
                  <p className="max-w-sm my-5">
                    From handcrafted interiors doors to precision made, striking
                    front doors, it all starts with us finding out your exact
                    requirements.
                  </p>
                  <div className="bg-darkgrey rounded-full flex m-auto h-9 w-9 justify-center items-center transition duration-500 ease-in-out group-hover:bg-gold">
                    <i
                      className="far fa-chevron-right text-white text-sm h-3"
                      aria-hidden
                    ></i>
                  </div>
                </Link>
              </div>
              <div className="flex-1 flex flex-col text-center justify-center items-center group relative lg:pb-0 pb-10 w-full lg:w-1/3 mb-5">
                <Link className="no-underline" to="/contact/">
                  <h3 className="font-display text-gold rfs:text-4xl max-w-xl">
                    Get in touch
                  </h3>
                  <div className="w-10 separator mt-4"></div>
                  <p className="max-w-sm my-5">
                    Whether it’s to book an appointment to visit us, get expert
                    advice or talk through your requirements, we’d love to hear
                    from you.
                  </p>
                  <div className="bg-darkgrey rounded-full flex m-auto h-9 w-9 justify-center items-center transition duration-500 ease-in-out group-hover:bg-gold">
                    <i
                      className="far fa-chevron-right text-white text-sm h-3"
                      aria-hidden
                    ></i>
                  </div>
                </Link>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default BlockCta
